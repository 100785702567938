import { Result } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './InfoComponent.module.scss';

/**
 * Returns the info component.
 * @returns the info component.
 */
const InfoComponent: React.FC = () => {
    return <Result status="404" className={styles.info} title={<FormattedMessage id="auth.info" />} />;
};
export default InfoComponent;
