import { List, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import trapPlotApi from '../../../apis/TrapPlotApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import InsectsVariationComponent from '../../../components/InsectsVariationComponent/InsectsVariationComponent';
import InsectsVariationStateComponent from '../../../components/InsectsVariationStateComponent/InsectsVariationStateComponent';
import CustomContext from '../../../contexts/CustomContext';
import { CustomAuth, TrapPlot } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import numericService from '../../../services/NumericService';
import styles from './NotificationsComponent.module.css';

/**
 * Returns the dashboard notifications component.
 * @returns the dashboard notifications component
 */
const NotificationsComponent: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [trapPlots, setTrapPlots] = useState<TrapPlot[]>([]);
    const [loading, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (auth.companyId) {
                    const trapPlotsPage = await trapPlotApi.list(0, 1000, 'id', true, undefined, true, 'ENABLED');
                    const trapPlots = trapPlotsPage.content
                        .filter((te) => te.latestTrapImage)
                        .sort((a, b) => numericService.sort(b.latestTrapImage?.insectsVariation, a.latestTrapImage?.insectsVariation))
                        .slice(0, 6);
                    setTrapPlots(trapPlots);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, auth.companyId]);

    /*** VISUAL ***/

    return (
        <List
            itemLayout="horizontal"
            dataSource={trapPlots}
            loading={loading === 'loading'}
            className={styles.list}
            renderItem={(trapPlot, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={
                            <InsectsVariationStateComponent
                                insectsVariationState={trapPlot.latestTrapImage?.insectsVariationState}
                                includeTooltip={true}
                                fontSize={24}
                            />
                        }
                        title={
                            <Link to={`/plots/${trapPlot.plot?.id}/traps/${trapPlot.id}`}>
                                <ConstantLabel prefix="plague.type." value={trapPlot.latestTrapImage?.plague} /> - {trapPlot.plot?.name}
                            </Link>
                        }
                        description={
                            <Space className={styles.description}>
                                <FormattedDate value={trapPlot.latestTrapImage?.date as any} year="numeric" month="2-digit" day="2-digit" />
                                <InsectsVariationComponent insectsVariation={trapPlot.latestTrapImage?.insectsVariation} />
                            </Space>
                        }
                    />
                </List.Item>
            )}
        />
    );
};
export default NotificationsComponent;
