import { Page } from '../models/Elements';
import { Trap } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class TrapApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, companyId?: number, searchText?: string): Promise<Page<Trap>> => {
        const response = await adminApiAxios.get<Page<Trap>>('/traps', {
            params: { page, size, sortField, sortOrder, companyId, searchText, status: 'ENABLED' }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((trap) => this.loadDates(trap));

        return response.data;
    };

    get = async (trapId: number): Promise<Trap> => {
        const response = await adminApiAxios.get<Trap>(`/traps/${trapId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (trap: Trap): Promise<Trap> => {
        const response = await adminApiAxios.post<Trap>(`/traps`, trap);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (trap: Trap): Promise<Trap> => {
        const response = await adminApiAxios.put<Trap>(`/traps/${trap.id}`, trap);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (trapId: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/traps/${trapId}`);

        return response.data;
    };

    /**
     * Loads the dates of a trap.
     * @param trap - the trap
     */
    private loadDates(trap: Trap) {
        trap.audit!.created = dateService.transformDate(trap.audit!.created)!;
        trap.audit!.updated = dateService.transformDate(trap.audit!.updated)!;
    }
}

const trapApi: TrapApi = new TrapApi();
export default trapApi;
