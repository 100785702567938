import { TablePaginationConfig } from 'antd/lib/table';
import { Page } from '../models/Elements';
import { PaginationConfig } from 'antd/es/pagination';
import { PaginationAlign, PaginationPosition } from 'antd/es/pagination/Pagination';

class PaginationService {
    readonly pageSize = 20;

    createPagination = (
        pageItems?: Page<any>,
        options?: { position?: TablePaginationPosition[]; pageSize?: number; hideOnSinglePage?: boolean }
    ): TablePaginationConfig => {
        const pageSize = options?.pageSize || this.pageSize;
        const hideOnSinglePage = options?.hideOnSinglePage || false;

        return {
            current: pageItems ? pageItems.number + 1 : 1,
            total: pageItems && pageItems.totalElements > 0 ? pageItems.totalElements : pageSize,
            pageSize: pageSize,
            showSizeChanger: false,
            size: 'default',
            hideOnSinglePage,
            position: options?.position || ['bottomRight']
        };
    };

    createMemoryPagination = (items: any[], position?: TablePaginationPosition[]): TablePaginationConfig => {
        return {
            total: items && items.length > 0 ? items.length : this.pageSize,
            pageSize: this.pageSize,
            showSizeChanger: false,
            size: 'default',
            hideOnSinglePage: false,
            position: position || ['bottomRight']
        };
    };

    createListPagination = (
        pageItems?: Page<any>,
        options?: {
            position?: PaginationPosition;
            align?: PaginationAlign;
            pageSize?: number;
            hideOnSinglePage?: boolean;
            onChange?: (page: number, pageSize: number) => void;
        }
    ): PaginationConfig => {
        const pageSize = options?.pageSize || this.pageSize;
        const hideOnSinglePage = options?.hideOnSinglePage || false;

        return {
            current: pageItems ? pageItems.number + 1 : 1,
            total: pageItems && pageItems.totalElements > 0 ? pageItems.totalElements : pageSize,
            pageSize: pageSize,
            showSizeChanger: false,
            size: 'default',
            hideOnSinglePage,
            position: options?.position || 'top',
            align: options?.align || 'end',
            onChange: options?.onChange
        };
    };
}

const paginationService: PaginationService = new PaginationService();
export default paginationService;

type TablePaginationPosition = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
