import { CalendarOutlined, ExclamationOutlined, EyeInvisibleOutlined, EyeOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Divider, Form, List, Row, Space } from 'antd';
import Search from 'antd/es/input/Search';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import notificationUserApi from '../../apis/NotificationUserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import {
    NotificationTrapImageInsectsCount,
    NotificationTrapImageInsectsVariation,
    NotificationTrapPlotPheromone,
    NotificationUser
} from '../../models/Entities';
import alertService from '../../services/AlertService';
import paginationService from '../../services/TableService';
import styles from './NotificationsPage.module.scss';
import CustomContext from '../../contexts/CustomContext';

/**
 * Returns the notifications page.
 * @returns the notifications page.
 */

const NotificationsPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [form] = Form.useForm();
    const { notifications, setNotifications } = useContext(CustomContext);
    const [filter, setFilter] = useState<Filter>({});
    const [notificationUsers, setNotificationUsers] = useState<NotificationUser[]>([]);
    const [page, setPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    //list notifications
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const notificationUserPage = await notificationUserApi.list(0, paginationService.pageSize, 'id', false, undefined, filter.searchText);
                setPage(notificationUserPage.number);
                setLastPage(notificationUserPage.last);
                setNotificationUsers(notificationUserPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, filter]);

    useEffect(() => {
        const init = async () => {
            try {
                if (notifications > 0 && notificationUsers.length > 0) {
                    await notificationUserApi.read();
                    setNotifications(0);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl, notificationUsers, notifications, setNotifications]);

    /*** METHODS ***/

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const loadMore = async () => {
        try {
            setLoading('loading');
            const notificationUserPage = await notificationUserApi.list(page + 1, paginationService.pageSize, 'id', false, undefined, filter.searchText);
            const updatedNotificationUsers = [...notificationUsers, ...notificationUserPage.content];
            setPage(notificationUserPage.number);
            setLastPage(notificationUserPage.last);
            setNotificationUsers(updatedNotificationUsers);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="notifications.title" />}
            menu="notifications"
            path={[{ path: '/notifications', name: <FormattedMessage id="notifications.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={20} lg={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'notifications.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <List
                dataSource={notificationUsers}
                size="large"
                itemLayout="vertical"
                loading={loading === 'loading'}
                loadMore={
                    notificationUsers && !lastPage ? (
                        <div className={styles.buttons}>
                            <Button type="primary" size="large" onClick={loadMore}>
                                <FormattedMessage id="button.loadMore" />
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )
                }
                renderItem={(notificationUser) => <NotificationComponent notificationUser={notificationUser} />}
            />
        </LayoutComponent>
    );
};
export default NotificationsPage;

interface Filter {
    searchText?: string;
}

const NotificationComponent: React.FC<NotificationProps> = (props) => {
    const { notificationUser } = props;
    const { notification } = notificationUser;

    const notificationLink = useMemo(() => {
        switch (notification.notificationType) {
            case 'TRAP_PLOT_PHEROMONE_REPLACEMENT':
                const notificationTrapPlotPheromone = notification.event as NotificationTrapPlotPheromone;
                return `/plots/${notificationTrapPlotPheromone.trapPlot.plot?.id}/traps/${notificationTrapPlotPheromone.trapPlot.id}`;
            case 'TRAP_IMAGE_INSECTS_VARIATION':
                const notificationTrapImageInsectsVariation = notification.event as NotificationTrapImageInsectsVariation;
                return `/plots/${notificationTrapImageInsectsVariation.plotId}/traps/${notificationTrapImageInsectsVariation.trapImage.trapPlotId}`;
            case 'TRAP_IMAGE_INSECTS_COUNT':
                const notificationTrapImageInsectsCount = notification.event as NotificationTrapImageInsectsCount;
                return `/plots/${notificationTrapImageInsectsCount.plotId}/traps/${notificationTrapImageInsectsCount.trapImage.trapPlotId}`;
        }
    }, [notification]);

    return (
        <List.Item
            key={notificationUser.id}
            actions={[
                <Space key="a" className={styles.space} split={<Divider type="vertical" />}>
                    <Space>
                        <CalendarOutlined />
                        <FormattedDate
                            value={notificationUser.notification.audit!.created as any}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </Space>

                    {notificationUser.read ? (
                        <Space>
                            <EyeOutlined />
                            <FormattedMessage id="notification.read" />
                        </Space>
                    ) : (
                        <Space>
                            <EyeInvisibleOutlined />
                            <FormattedMessage id="notification.unread" />
                        </Space>
                    )}
                    <Link to={notificationLink} className={styles.link}>
                        <Button size="small" type="primary" ghost icon={<RightCircleOutlined />}>
                            <FormattedMessage id="notification.view" />
                        </Button>
                    </Link>
                </Space>
            ]}
        >
            <List.Item.Meta
                avatar={
                    <Avatar
                        size="small"
                        icon={<ExclamationOutlined />}
                        className={`${styles.avatar} ${notification.level === 'HIGH' ? styles.high : styles.medium}`}
                    />
                }
                title={notification.name}
                description={notification.description}
            />
        </List.Item>
    );
};

interface NotificationProps {
    notificationUser: NotificationUser;
}
