import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Space } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import userApi from '../../../apis/UserApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { User } from '../../../models/Entities';
import { userTypes } from '../../../models/Types';
import alertService from '../../../services/AlertService';

/**
 * Returns the user page.
 * @returns the user page.
 */
const AdminUserPage: React.FC = () => {
    /***HOOKS***/
    const intl = useIntl();
    const { modal } = App.useApp();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [user, setUser] = useState<User>();
    const params = useParams<ParamsType>();

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                const userId = params.id;
                let user: User;
                if (userId === 'new') {
                    user = { type: 'ADMIN' };
                } else {
                    user = await userApi.get(+userId!);
                }
                form.setFieldsValue(user);
                setUser(user);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedUser: User = Object.assign({}, user, values);
            if (updatedUser.id) {
                updatedUser = await userApi.update(updatedUser);
            } else {
                updatedUser = await userApi.create(updatedUser);
            }
            setUser(updatedUser);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl, [{ status: 409, message: 'user.email.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'user.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    if (user && user.id) {
                        setLoading('deleting');
                        await userApi.delete(user.id);
                        message.success(intl.formatMessage({ id: 'status.deleted' }));
                        navigate('/admin/users');
                    }
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const userTypeOptions = userTypes
        .filter((u) => u === 'ADMIN')
        .map((t) => (
            <Select.Option key={t} value={t}>
                <FormattedMessage id={`user.type.${t}`} />
            </Select.Option>
        ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="user.title" />}
            menu="users"
            path={[
                { path: '/users', name: <FormattedMessage id="users.title" /> },
                { path: `/users/${user && user.id ? user.id : params.id}`, name: user && user.id && user.email }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="user.email" />}
                            name="email"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />
                                }
                            ]}
                        >
                            <Input size="large" maxLength={200} disabled={user && !!user.id} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="user.type" />}
                            name="type"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large" disabled>
                                {userTypeOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="user.firstName" />}
                            name="firstName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="user.lastName" />}
                            name="lastName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            <Link to="/admin/users">
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {user && user.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};

export default AdminUserPage;
type ParamsType = { id: string };
