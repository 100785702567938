import { Col, Form, Modal, Row, Select, message } from 'antd';
import { Plot, TrapPlot } from '../../../models/Entities';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import alertService from '../../../services/AlertService';
import trapPlotApi from '../../../apis/TrapPlotApi';
import { useNavigate } from 'react-router-dom';

const TrapPlotChangeModal = (props: Props) => {
    const { plots, trapPlot } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const navigate = useNavigate();

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('saving');
            const values = await form.validateFields();
            let updatedTrapPlot: TrapPlot = Object.assign(trapPlot, values);
            await trapPlotApi.updatePlot(updatedTrapPlot);
            message.success(intl.formatMessage({ id: 'status.saved' }));
            navigate(`/plots/${updatedTrapPlot!.plot!.id}`);
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/
    //filter to remove the current plot id
    const plotsOptions = plots
        .filter((p) => p.id !== trapPlot!.plot!.id)
        .map((p) => (
            <Select.Option key={p.id} value={p.id}>
                {p.name}
            </Select.Option>
        ));

    return (
        <Modal
            title={<FormattedMessage id="trapPlot.modal.plot.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={800}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="trapPlot.modal.plot.select" />} name={['plot', 'id']}>
                            <Select size="large">{plotsOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default TrapPlotChangeModal;

interface Props {
    plots: Plot[];
    trapPlot: TrapPlot;
    onCancel: () => void;
}
