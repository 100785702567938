class Configuration {
    // domains of environments (hardcoded - react doesn't allow changes on runtime)
    readonly testDomain = 'test.agrisens.es';
    readonly stageDomain = 'stage.agrisens.es';
    readonly productionDomain = 'agrisens.es';

    // configuration attributes
    coreApiUrl: string | undefined;
    awsAuth: any | undefined;

    constructor() {
        // set up configuration attributes based on environment
        if (window.location.hostname.endsWith(this.testDomain)) {
        } else if (window.location.hostname.endsWith(this.stageDomain)) {
        } else if (window.location.hostname.endsWith(this.productionDomain)) {
            this.coreApiUrl = 'https://core.api.agrisens.es';
            this.awsAuth = {
                Auth: {
                    region: 'eu-central-1',
                    userPoolId: 'eu-central-1_7BfCWPkhu',
                    userPoolWebClientId: '3dpuqtn08v1dinbeqsin8h0r8d',
                    authenticationFlowType: 'USER_SRP_AUTH'
                }
            };
            // } else {
            //     this.coreApiUrl = 'https://core.api.agrisens.es';
            //     this.awsAuth = {
            //         Auth: {
            //             region: 'eu-central-1',
            //             userPoolId: 'eu-central-1_7BfCWPkhu',
            //             userPoolWebClientId: '3dpuqtn08v1dinbeqsin8h0r8d',
            //             authenticationFlowType: 'USER_SRP_AUTH'
            //         }
            //     };
        } else {
            this.coreApiUrl = 'http://localhost:8010';
            this.awsAuth = {
                Auth: {
                    region: 'eu-central-1',
                    userPoolId: 'eu-central-1_yZGj1BDMt',
                    userPoolWebClientId: '72h9vir44997thb957iqtndbld',
                    authenticationFlowType: 'USER_SRP_AUTH'
                }
            };
        }
    }
}
const configuration: Configuration = new Configuration();
export default configuration;
