import { provinces } from 'all-spanish-cities';

/**
 * Returns the province component.
 * @returns the province component.
 */
const ProvinceComponent: React.FC<Props> = (props) => {
    const { provinceId } = props;
    const province = provinces().find((p) => p.code === provinceId);

    return <>{province?.name}</>;
};
export default ProvinceComponent;

interface Props {
    provinceId: string | undefined;
}
