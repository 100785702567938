import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Image, Row } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import PublicLayoutComponent from '../../components/PublicLayoutComponent/PublicLayoutComponent';
import contact from '../../resources/images/contact.svg';
import styles from './ContactPage.module.scss';

/**
 * Returns the contact page.
 * @returns the contact page.
 */
const ContactPage: React.FC = () => {
    /*** HOOKS ***/

    const [desktop] = useResponsiveLayout();

    // initialize app
    useEffect(() => {
        const init = async () => {};
        init();
    }, []);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <PublicLayoutComponent>
            <div className={`${styles.container} ${!desktop && styles.mobile}`}>
                <div className={styles.top}>
                    <Row>
                        <Col className={styles.description} xs={24} sm={24} md={24} lg={16} xl={12}>
                            <h2>
                                <FormattedMessage id="contact.header" />
                            </h2>
                            <p>
                                <FormattedMessage id="contact.description.1" />
                            </p>
                            <p>
                                <MailOutlined className={styles.icon} />
                                <a href="mailto:info@agrisens.es">info@agrisens.es</a>
                            </p>
                            <p>
                                <PhoneOutlined className={styles.icon} />
                                <FormattedMessage id="contact.phone" />
                            </p>
                        </Col>
                        <Col className={styles.imageContainer} xs={24} sm={24} md={24} lg={8} xl={12}>
                            <Image src={contact} alt="Contact" className={styles.image} preview={false} />
                        </Col>
                    </Row>
                </div>
            </div>
        </PublicLayoutComponent>
    );
};
export default ContactPage;
