import { Spin } from 'antd';
import L, { LatLngBoundsLiteral, LatLngTuple } from 'leaflet';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import trapPlotApi from '../../apis/TrapPlotApi';
import CustomContext from '../../contexts/CustomContext';
import { CustomAuth, Plot, TrapPlot, TrapImage } from '../../models/Entities';
import markerGreenSvg from '../../resources/images/marker-green.svg';
import markerOrangeSvg from '../../resources/images/marker-orange.svg';
import markerRedSvg from '../../resources/images/marker-red.svg';
import markerSvg from '../../resources/images/marker.svg';
import alertService from '../../services/AlertService';
import styles from './MapComponent.module.css';
import { Link } from 'react-router-dom';
import InsectsVariationComponent from '../InsectsVariationComponent/InsectsVariationComponent';
import InsectsVariationStateComponent from '../InsectsVariationStateComponent/InsectsVariationStateComponent';
import ConstantLabel from '../ConstantLabel/ConstantLabel';

/**
 * Returns the traps map.
 * @returns the map
 */
const MapComponent: React.FC<Props> = (props) => {
    const { plot, trapPlot } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [trapPlots, setTrapPlots] = useState<TrapPlot[]>([]);
    const [loading, setLoading] = useState<'loading'>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (auth.companyId) {
                    const trapPlotsPage = await trapPlotApi.list(0, 1000, 'id', true, plot?.id, true, 'ENABLED');
                    let trapPlots = trapPlotsPage.content.filter((te) => te.latestTrapImage);
                    if (trapPlot && trapPlot.id) {
                        trapPlots = trapPlots.filter((te) => te.id === trapPlot.id);
                    }
                    setTrapPlots(trapPlots);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [plot, intl, auth.companyId, trapPlot]);

    /*** VISUAL ***/

    const latitude = 40.483; // TODO: to be calculated automatically using the trap images coordinates
    const longitude = -4.0876; // TODO: to be calculated automatically using the trap images coordinates
    const zoom = 6;

    const markers = trapPlots.map((trapPlot) => {
        const markerIcon = getMarkerIcon(trapPlot.latestTrapImage!);

        return (
            <Marker
                key={trapPlot.latestTrapImage!.trapUuid}
                position={[trapPlot.latestTrapImage!.coordinates!.latitude, trapPlot.latestTrapImage!.coordinates!.longitude]}
                icon={markerIcon}
            >
                <Popup offset={[-18, -25]}>
                    <strong>{trapPlot.plot?.name}</strong>
                    <br />
                    <FormattedMessage id="map.trap" />: <FormattedMessage id={trapPlot.trap?.shortUuid} />
                    <br />
                    <FormattedMessage id="map.plague" />: <ConstantLabel value={trapPlot.latestTrapImage!.plague} prefix="plague.type." />
                    <br />
                    <FormattedMessage id="map.insects" />: {trapPlot.latestTrapImage!.insectsCount}
                    <br />
                    <FormattedMessage id="map.crop" />: <ConstantLabel value={trapPlot.latestTrapImage!.crop} prefix="crop.type." />
                    <br />
                    <FormattedMessage id="map.insectsVariation" />: <InsectsVariationComponent insectsVariation={trapPlot.latestTrapImage?.insectsVariation} />
                    <div className={styles.state}>
                        <InsectsVariationStateComponent
                            insectsVariationState={trapPlot.latestTrapImage?.insectsVariationState}
                            fontSize={16}
                            includeLabel={true}
                        />
                    </div>
                    <Link to={`/plots/${trapPlot.plot?.id}/traps/${trapPlot.id}`} className={styles.link}>
                        <FormattedMessage id="map.link" />
                    </Link>
                </Popup>
            </Marker>
        );
    });

    return (
        <div className={styles.container}>
            {loading === 'loading' && <Spin size="large" className={styles.spin} />}
            <MapContainer zoom={zoom} center={[latitude, longitude]} scrollWheelZoom={false} style={{ width: '100%', height: '600px' }} className={styles.map}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MapViewer center={[latitude, longitude]} zoom={zoom} trapPlots={trapPlots} />
                {markers}
            </MapContainer>
        </div>
    );
};
export default MapComponent;

interface Props {
    plot?: Plot;
    trapPlot?: TrapPlot;
}

/**
 * Map viewer component for fit bounds to markers.
 * @param props the props
 * @returns the map viewer component
 */
const MapViewer: React.FC<MapViewerProps> = (props) => {
    const { trapPlots } = props;

    const map = useMap();
    const markers: LatLngBoundsLiteral = trapPlots
        .map((trapPlot) => trapPlot.latestTrapImage!)
        .map((trapImage) => [trapImage.coordinates!.latitude, trapImage.coordinates!.longitude] as LatLngTuple);

    map.setView(props.center, props.zoom);
    if (markers.length && markers.length > 0) {
        map.fitBounds(markers, { padding: [11, 11], maxZoom: 15 });
    }

    return null;
};
interface MapViewerProps {
    trapPlots: TrapPlot[];
    center: L.LatLngExpression;
    zoom: number | undefined;
}

/*** MARKER ***/

const markerRed = L.icon({
    iconUrl: markerRedSvg,
    iconRetinaUrl: markerRedSvg,
    iconSize: [36, 36],
    iconAnchor: [36, 36]
});
const markerOrange = L.icon({
    iconUrl: markerOrangeSvg,
    iconRetinaUrl: markerOrangeSvg,
    iconSize: [36, 36],
    iconAnchor: [36, 36]
});
const markerGreen = L.icon({
    iconUrl: markerGreenSvg,
    iconRetinaUrl: markerGreenSvg,
    iconSize: [36, 36],
    iconAnchor: [36, 36]
});
const marker = L.icon({
    iconUrl: markerSvg,
    iconRetinaUrl: markerSvg,
    iconSize: [36, 36],
    iconAnchor: [36, 36]
});

const getMarkerIcon = (trapImage: TrapImage) => {
    let currentMarker = marker;
    if (trapImage.insectsVariationState === 'HIGH') {
        currentMarker = markerRed;
    } else if (trapImage.insectsVariationState === 'MEDIUM') {
        currentMarker = markerOrange;
    } else if (trapImage.insectsVariationState === 'LOW') {
        currentMarker = markerGreen;
    } else {
        currentMarker = marker;
    }

    return currentMarker;
};
