import { Auth } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userApi from '../apis/UserApi';
import { CustomAuth, SignUpUser, User } from '../models/Entities';
import { AuthorityType } from '../models/Types';

class AuthService {
    auth: CustomAuth | undefined;
    readonly authority = 'authority';

    get = async (): Promise<CustomAuth | undefined> => {
        try {
            const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
            if (cognitoUser) {
                this.auth = await this.createAuth();
            }
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    signIn = async (username: string, password: string): Promise<CustomAuth | any> => {
        const formattedUsername = username ? username.trim().toLowerCase() : username;
        const cognitoUser: CognitoUser = await Auth.signIn(formattedUsername, password);
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return cognitoUser;
        } else {
            this.auth = await this.createAuth();
            return this.auth;
        }
    };

    signUp = async (signUpUser: SignUpUser): Promise<void> => {
        const signUpParams = {
            username: signUpUser.username.trim().toLowerCase(),
            password: signUpUser.password
        };
        await Auth.signUp(signUpParams);
    };

    confirmSignUp = async (signUpUser: SignUpUser, code: string): Promise<CustomAuth | any> => {
        await Auth.confirmSignUp(signUpUser.username, code);
        const response = await this.signIn(signUpUser.username, signUpUser.password);

        return response;
    };

    signOut = async () => {
        await Auth.signOut();
        this.auth = undefined;
    };

    completeNewPassword = async (cognitoUser: CognitoUser, password: string): Promise<CustomAuth | undefined> => {
        await Auth.completeNewPassword(cognitoUser, password);
        this.auth = await this.createAuth();

        return this.auth;
    };

    forgotPassword = async (username: string) => {
        const formattedUsername = username ? username.trim().toLowerCase() : username;

        await Auth.forgotPassword(formattedUsername);
    };

    resetPassword = async (username: string, code: string, password: string) => {
        const formattedUsername = username ? username.trim().toLowerCase() : username;

        await Auth.forgotPasswordSubmit(formattedUsername, code, password);
        return await this.signIn(formattedUsername, password); // TODO: to be removed
    };

    getAccessToken = async () => {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
    };

    getCognitoUser = async (): Promise<any> => {
        const cognitoUserInfo = await Auth.currentUserInfo();
        return cognitoUserInfo;
    };

    /**
     * Returns the auth from the current user.
     * @returns the auth
     */
    private async createAuth(): Promise<CustomAuth | undefined> {
        // get current user
        let customAuth: CustomAuth | undefined;
        try {
            const currentUser = await userApi.getCurrent();
            const authorities = this.createAuthorities(currentUser);
            customAuth = {
                id: currentUser.id,
                companyId: currentUser.companyId,
                email: currentUser.email,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                authorities,
                initialized: true
            };
        } catch (error: any) {
            // user not authenticated in resource server - non initialized user
            if (error && error.response && error.response.status === 404) {
                customAuth = {
                    id: 0,
                    companyId: 0,
                    email: '',
                    firstName: '',
                    lastName: '',
                    authorities: [],
                    initialized: false
                };
            }
        }

        return customAuth;
    }

    /**
     * Returns the authorities of a user.
     * @param user the user
     * @returns the authorities
     */
    private createAuthorities(user: User): AuthorityType[] {
        const authorities: AuthorityType[] = [];
        if (user.type === 'ADMIN') {
            authorities.push('ROLE_ADMIN');
            authorities.push('ROLE_COMPANY_ADMIN');
            authorities.push('ROLE_COMPANY_USER');
        } else if (user.type === 'COMPANY_ADMIN') {
            authorities.push('ROLE_COMPANY_ADMIN');
            authorities.push('ROLE_COMPANY_USER');
        } else if (user.type === 'COMPANY_USER') {
            authorities.push('ROLE_COMPANY_USER');
        }

        return authorities;
    }
}

const authService: AuthService = new AuthService();
export default authService;
