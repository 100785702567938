import { CheckCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { InsectsVariationStateType } from '../../models/Types';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import styles from './InsectsVariationStateComponent.module.css';

/**
 * Returns the icon corresponding an insects variation state.
 * @param props the props
 * @returns  the icon
 */
const InsectsVariationStateComponent: React.FC<InsectsVariationStateProps> = (props) => {
    const { insectsVariationState, fontSize, includeLabel, includeTooltip } = props;

    switch (insectsVariationState) {
        case 'HIGH':
            return (
                <Tooltip title={includeTooltip && <ConstantLabel prefix="trapImage.insectsVariationState." value={insectsVariationState} />}>
                    <div className={`${styles.container} ${styles.high}`}>
                        <ExclamationCircleOutlined className={styles.icon} style={{ fontSize }} />
                        {includeLabel && (
                            <span className={styles.label}>
                                <ConstantLabel prefix="trapImage.insectsVariationState." value={insectsVariationState} />
                            </span>
                        )}
                    </div>
                </Tooltip>
            );
        case 'MEDIUM':
            return (
                <Tooltip title={includeTooltip && <ConstantLabel prefix="trapImage.insectsVariationState." value={insectsVariationState} />}>
                    <div className={`${styles.container} ${styles.medium}`}>
                        <ExclamationCircleOutlined className={styles.icon} style={{ fontSize }} />
                        {includeLabel && (
                            <span className={styles.label}>
                                <ConstantLabel prefix="trapImage.insectsVariationState." value={insectsVariationState} />
                            </span>
                        )}
                    </div>
                </Tooltip>
            );
        case 'LOW':
            return (
                <Tooltip title={includeTooltip && <ConstantLabel prefix="trapImage.insectsVariationState." value={insectsVariationState} />}>
                    <div className={`${styles.container} ${styles.low}`}>
                        <CheckCircleOutlined className={styles.icon} style={{ fontSize }} />
                        {includeLabel && (
                            <span className={styles.label}>
                                <ConstantLabel prefix="trapImage.insectsVariationState." value={insectsVariationState} />
                            </span>
                        )}
                    </div>
                </Tooltip>
            );
        default:
            return (
                <Tooltip title={includeTooltip && <ConstantLabel prefix="trapImage.insectsVariationState." value="UNKNOWN" />}>
                    <div className={`${styles.container} ${styles.unknown}`}>
                        <QuestionCircleOutlined className={`${styles.icon} ${styles.unknown}`} style={{ fontSize }} />
                        {includeLabel && (
                            <span className={styles.label}>
                                <ConstantLabel prefix="trapImage.insectsVariationState." value="UNKNOWN" />
                            </span>
                        )}
                    </div>
                </Tooltip>
            );
    }
};
export default InsectsVariationStateComponent;

interface InsectsVariationStateProps {
    insectsVariationState: InsectsVariationStateType | undefined;
    fontSize?: number;
    includeLabel?: boolean;
    includeTooltip?: boolean;
}
