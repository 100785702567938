import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import trapImageApi from '../../../apis/TrapImageApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import InsectsVariationStateTagComponent from '../../../components/InsectsVariationStateTagComponent/InsectsVariationStateTagComponent';
import { Page } from '../../../models/Elements';
import { Plot, TrapImage, TrapPlot } from '../../../models/Entities';
import { InsectsVariationStateType } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import paginationService from '../../../services/TableService';

/**
 * Returns the trap images component.
 * @returns the traps images component.
 */
const TrapImagesComponent: React.FC<Props> = (props) => {
    const { trapPlot } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [trapImagesPage, setTrapImagesPage] = useState<Page<TrapImage>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('date');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (trapPlot && trapPlot.id) {
                    const trapImagesPage = await trapImageApi.list(page, 20, sortField, sortOrder, undefined, trapPlot.id);
                    setTrapImagesPage(trapImagesPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, trapPlot]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    /*** VISUAL ***/

    const items = trapImagesPage ? trapImagesPage.content : [];
    const columns: ColumnsType<TrapImage> = [
        {
            title: <FormattedMessage id="trapImage.date" />,
            dataIndex: 'date',
            key: 'date',
            width: 200,
            align: 'center',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: Dayjs) =>
                value && <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" />
        },
        {
            title: <FormattedMessage id="trapImage.plague" />,
            dataIndex: 'plague',
            key: 'plague',
            width: 140,
            render: (value: string | undefined, plot: Plot) => <ConstantLabel value={value} prefix="plague.type." />
        },
        {
            title: <FormattedMessage id="trapImage.insectsCount" />,
            dataIndex: 'insectsCount',
            key: 'insectsCount',
            width: 120,
            align: 'right',
            sorter: true,
            render: (value: number) => <strong>{value}</strong>
        },
        {
            title: <FormattedMessage id="trapImage.temperature" />,
            dataIndex: 'temperature',
            key: 'temperature',
            width: 120,
            align: 'right',
            sorter: true,
            render: (value: number | undefined) =>
                value && (
                    <FormattedNumber
                        value={value}
                        minimumFractionDigits={1}
                        maximumFractionDigits={1}
                        unit="celsius"
                        unitDisplay="short"
                        style={'unit' as any}
                    />
                )
        },
        {
            title: <FormattedMessage id="trapImage.humidity" />,
            dataIndex: 'humidity',
            key: 'humidity',
            width: 120,
            align: 'right',
            sorter: true,
            render: (value: number | undefined) =>
                value && (
                    <FormattedNumber
                        value={value / 100}
                        minimumFractionDigits={1}
                        maximumFractionDigits={1}
                        unit="percent"
                        unitDisplay="short"
                        style={'percent' as any}
                    />
                )
        },
        {
            title: <FormattedMessage id="trapImage.coordinates.latitude" />,
            dataIndex: ['coordinates', 'latitude'],
            key: 'latitude',
            width: 120,
            align: 'right',
            render: (value: number | undefined) => value && <FormattedNumber value={value} minimumFractionDigits={4} maximumFractionDigits={4} />
        },
        {
            title: <FormattedMessage id="trapImage.coordinates.longitude" />,
            dataIndex: ['coordinates', 'longitude'],
            key: 'longitude',
            width: 120,
            align: 'right',
            render: (value: number | undefined) => value && <FormattedNumber value={value} minimumFractionDigits={4} maximumFractionDigits={4} />
        },
        {
            title: <FormattedMessage id="trapImage.crop" />,
            dataIndex: 'crop',
            key: 'crop',
            width: 120,
            render: (value: string | undefined, plot: Plot) => <ConstantLabel value={value} prefix="crop.type." />
        },
        {
            title: <FormattedMessage id="trapImage.farming" />,
            dataIndex: 'farming',
            key: 'farming',
            width: 120,
            render: (value: string | undefined, plot: Plot) => <ConstantLabel value={value} prefix="farming.type." />
        },
        {
            title: <FormattedMessage id="trapImage.pheromoneRenewed" />,
            dataIndex: 'pheromoneRenewed',
            key: 'pheromoneRenewed',
            width: 120,
            render: (value: Dayjs) => value && <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" />
        },

        {
            title: <FormattedMessage id="trapImage.insectsVariationState" />,
            dataIndex: 'insectsVariationState',
            key: 'insectsVariationState',
            width: 120,
            align: 'center',
            render: (value: InsectsVariationStateType | undefined) => <InsectsVariationStateTagComponent insectsVariationState={value} />
        }
    ];

    return (
        <Table
            dataSource={items}
            columns={columns}
            pagination={paginationService.createPagination(trapImagesPage, { hideOnSinglePage: true })}
            rowKey="id"
            onChange={handleTableChange}
            sortDirections={['ascend', 'descend']}
            showSorterTooltip={false}
            loading={loading === 'loading'}
            className="table"
        />
    );
};
export default TrapImagesComponent;

interface Props {
    trapPlot: TrapPlot;
}
