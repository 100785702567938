import { SaveOutlined } from '@ant-design/icons';
import { Form, Input, message } from 'antd';
import { Button, Col, Row, Space } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import companyApi from '../../../apis/CompanyApi';
import userApi from '../../../apis/UserApi';
import { Company } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import AddressComponent from '../../../components/AddressComponent/AddressComponent';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

/***Returns the account business component.
 * @returns the account business component.
 */

const AccountCompanyComponent: React.FC = () => {
    /*** HOOKS ***/
    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'initializing' | 'loading'>();
    const [company, setCompany] = useState<Company>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                //load current user data
                const user = await userApi.getCurrent();
                //load current user company
                const companyId = user.companyId;
                const company = await companyApi.get(companyId!);
                form.setFieldsValue(company);
                setCompany(company);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');
            let updatedCompany: Company = Object.assign({}, company, values);

            if (company) {
                updatedCompany = await companyApi.update(company?.id!, updatedCompany);
                message.success(intl.formatMessage({ id: 'status.saved' }));

                setCompany(updatedCompany);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL */

    return (
        <Form form={form} onFinish={save} colon={false} layout="vertical">
            <Row gutter={[24, 24]}>
                <Col span={24} lg={22}>
                    <Form.Item
                        label={<FormattedMessage id="company.name" />}
                        name="name"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" maxLength={200} />
                    </Form.Item>
                </Col>
            </Row>
            <AddressComponent field="contactAddress" title={<FormattedMessage id="company.contactAddress" />} disabled={true} />
            <AddressComponent field="billingAddress" title={<FormattedMessage id="company.billingAddress" />} disabled={true} />

            <div hidden={true}>
                <Row gutter={[24, 24]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />} disabled>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </div>
            <Row gutter={[24, 24]}>
                <Col span={16}>
                    <Link to="/">
                        <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                    </Link>
                </Col>
            </Row>
        </Form>
    );
};
export default AccountCompanyComponent;
