import { Tabs, TabsProps } from 'antd';
import { FormattedMessage } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import AccountCompanyComponent from './AccountCompanyComponent/AccountCompanyComponent';
import AccountUserComponent from './AccountUserComponent/AccountUserComponent';
import { useContext } from 'react';
import CustomContext from '../../contexts/CustomContext';

/***Returns the settings page.
 * @returns the settings page.
 */
const SettingsPage: React.FC = () => {
    /***HOOKS***/

    const { auth } = useContext(CustomContext);

    /***METHODS***/

    /***VISUAL***/

    let items: TabsProps['items'] = [
        {
            key: 'user',
            label: <FormattedMessage id="settings.user" />,
            children: <AccountUserComponent />,
            forceRender: true
        }
    ];
    if (auth && auth.companyId) {
        items = [
            ...items,
            {
                key: 'company',
                label: <FormattedMessage id="settings.company" />,
                children: <AccountCompanyComponent />,
                forceRender: true
            }
        ];
    }

    return (
        <LayoutComponent
            title={<FormattedMessage id="settings.title" />}
            menu="settings"
            path={[{ path: '/settings', name: <FormattedMessage id="settings.title" /> }]}
        >
            <Tabs defaultActiveKey="account" items={items} />
        </LayoutComponent>
    );
};
export default SettingsPage;
