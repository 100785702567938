import { Layout, Row, Space } from 'antd';
import React, { PropsWithChildren, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../resources/images/agrisens-icon.svg';
import useResponsiveLayout from '../LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import styles from './PublicLayoutComponent.module.scss';
import CustomContext from '../../contexts/CustomContext';
import { CustomAuth } from '../../models/Entities';

const { Header, Footer } = Layout;

const PublicLayoutComponent: React.FC<Props> = (props) => {
    const { children } = props;

    /*** HOOKS ***/

    const [desktop] = useResponsiveLayout();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <Layout className={styles.layout}>
            <Header className={styles.header}>
                <Link to="/" className={styles.title}>
                    <LogoIcon className={styles.logo} /> <span>AgriSens</span>
                </Link>
                <Space>
                    {(!auth || !auth.initialized) && (
                        <Link to="/signin" className={styles.options}>
                            <span>
                                <FormattedMessage id="signIn.login.link.home" />
                            </span>
                        </Link>
                    )}
                    {auth && auth.initialized && (
                        <Link to="/dashboard" className={styles.options}>
                            <span>
                                <FormattedMessage id="home.main.dashboard.link" />
                            </span>
                        </Link>
                    )}
                    <Link to="/contact" className={styles.options}>
                        <span>
                            <FormattedMessage id="home.main.contact.link" />
                        </span>
                    </Link>
                </Space>
            </Header>
            <Row className={styles.content} justify="center">
                {children}
            </Row>
            <Footer className={desktop ? styles.footer : styles.mobileFooter}>
                <div>
                    <Space className={styles.links} size="large">
                        <Link to="/legal-notice" className={styles.link}>
                            <FormattedMessage id="common.legalNotice" />
                        </Link>
                        <Link to="/privacy-policy" className={styles.link}>
                            <FormattedMessage id="common.privacyPolicy" />
                        </Link>
                    </Space>
                </div>
                <div className={styles.copyright}>
                    <a href="https://agrisens.es" target="_blank" rel="noreferrer">
                        ©2024 AgriSens Technologies S.L.
                    </a>
                </div>
            </Footer>
        </Layout>
    );
};
export default PublicLayoutComponent;

interface Props extends PropsWithChildren {}
