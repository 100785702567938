import { Tag } from 'antd';
import { InsectsVariationStateType } from '../../models/Types';
import ConstantLabel from '../ConstantLabel/ConstantLabel';

/**
 * Returns the tag corresponding an insects variation state.
 * @param props the props
 * @returns  the icon
 */
const InsectsVariationStateTagComponent: React.FC<Props> = (props) => {
    const { insectsVariationState } = props;

    switch (insectsVariationState) {
        case 'HIGH':
            return (
                <Tag color="red">
                    <ConstantLabel prefix="trapImage.insectsVariationState.table." value={insectsVariationState} />
                </Tag>
            );
        case 'MEDIUM':
            return (
                <Tag color="orange">
                    <ConstantLabel prefix="trapImage.insectsVariationState.table." value={insectsVariationState} />
                </Tag>
            );
        case 'LOW':
            return (
                <Tag color="green">
                    <ConstantLabel prefix="trapImage.insectsVariationState.table." value={insectsVariationState} />
                </Tag>
            );
        default:
            return <></>;
    }
};
export default InsectsVariationStateTagComponent;

interface Props {
    insectsVariationState: InsectsVariationStateType | undefined;
}
