import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Row, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import companyApi from '../../../apis/CompanyApi';
import AddressComponent from '../../../components/AddressComponent/AddressComponent';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { Company } from '../../../models/Entities';
import alertService from '../../../services/AlertService';

/**
 * Returns the admin company page.
 * @returns the admin company page.
 */
const AdminCompanyPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const { modal } = App.useApp();
    const [form] = Form.useForm();
    const params = useParams<ParamsType>();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [company, setCompany] = useState<Company>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                // load company
                let company: Company;
                company = await companyApi.get(+params.id!);
                form.setFieldsValue(company);
                setCompany(company);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedCompany: Company = Object.assign({}, company, values);
            if (updatedCompany.id) {
                updatedCompany = await companyApi.update(updatedCompany.id, updatedCompany);
            }

            message.success(intl.formatMessage({ id: 'status.saved' }));
            setCompany(updatedCompany);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'company.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await companyApi.delete(company?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/admin/companies');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="companies.title" />}
            menu="companies"
            path={[
                { path: '/admin/companies', name: <FormattedMessage id="companies.title" /> },
                { path: `/admin/companies/${params.id}`, name: company?.name }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={22}>
                        <Form.Item
                            label={<FormattedMessage id="company.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>
                <AddressComponent field="contactAddress" title={<FormattedMessage id="company.contactAddress" />} />
                <AddressComponent field="billingAddress" title={<FormattedMessage id="company.billingAddress" />} />
                <Row gutter={[24, 24]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Link to="/admin/companies">
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            {
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                            }
                            {company && company.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default AdminCompanyPage;
type ParamsType = { id: string };
