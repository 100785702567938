import { createContext } from 'react';
import { Context } from '../models/Elements';

const CustomContext = createContext<Context>({
    auth: undefined,
    setAuth: () => {},
    notifications: 0,
    setNotifications: () => {}
});
export default CustomContext;
