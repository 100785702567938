import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';
import { AuthorityType } from '../../models/Types';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const ProtectedRoute: React.FC<Props> = (props) => {
    const { authorities, redirectPath, children } = props;
    const context = useContext(CustomContext);
    const { auth } = context;

    const deeplink = sessionStorage.getItem('deeplink');
    if (auth && auth.initialized && deeplink) {
        sessionStorage.removeItem('deeplink');
        return <Navigate to={deeplink} />;
    } else if (auth && auth.initialized && (!authorities || auth.authorities.some((a) => authorities.includes(a)))) {
        return children ? children : <Outlet />;
    } else if (auth && !auth.initialized && !authorities) {
        return children ? children : <Outlet />;
    } else if (auth && !auth.initialized) {
        return <Navigate to={'/init'} />;
    } else {
        return <Navigate to={redirectPath || '/'} />;
    }
};
export default ProtectedRoute;

interface Props {
    children?: React.ReactElement;
    authorities?: AuthorityType[];
    redirectPath?: string;
}
