import { Page } from '../models/Elements';
import { NotificationUser } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class NotficationUserApi {
    /**
     * Lists notification users.
     * @params page: page, size: page size, sortField: sort field, sortOrder: sort order, read: read status, searchText: search text
     * @returns the notification users
     */
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, read?: boolean, searchText?: string): Promise<Page<NotificationUser>> => {
        const response = await adminApiAxios.get<Page<NotificationUser>>(`/notification-users`, {
            params: { page, size, sortField, sortOrder, read, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((notificationUser) => this.loadDates(notificationUser));

        return response.data;
    };

    /**
     * Returns a notification user.
     * @param id the id
     * @returns the notification user
     */
    get = async (id: number): Promise<NotificationUser> => {
        const response = await adminApiAxios.get<NotificationUser>(`/notification-users/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    /**
     * Updates the notification user status to read (on loading the notifications).
     * @returns void
     */
    read = async (): Promise<void> => {
        const response = await adminApiAxios.post<void>(`/notification-users/reads`);

        return response.data;
    };
    /**
     * Counts the number of read or unread notification users.
     * @returns the number
     */
    countRead = async (read: boolean): Promise<number> => {
        const response = await adminApiAxios.get<number>(`/notification-users/reads/count`, { params: { read } });

        return response.data;
    };

    /**
     * Loads the dates of a notification user.
     * @param notificationUser - the notification user
     */
    private loadDates(notificationUser: NotificationUser) {
        notificationUser.notification.audit!.created = dateService.transformDate(notificationUser.notification.audit!.created)!;
        notificationUser.notification.audit!.updated = dateService.transformDate(notificationUser.notification.audit!.updated)!;
        notificationUser.sent = dateService.transformDate(notificationUser.sent)!;
    }
}

const notificationUserApi: NotficationUserApi = new NotficationUserApi();
export default notificationUserApi;
