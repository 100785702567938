import { Button, Card, Col, Image, List, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import trapImageApi from '../../../apis/TrapImageApi';
import { TrapImage, TrapPlot } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './TrapImagesViewerComponent.module.scss';

/**
 * Returns the trap images viewer component.
 * @returns the traps images viewer component.
 */
const TrapImagesViewerComponent: React.FC<Props> = (props) => {
    const { trapPlot } = props;

    /*** HOOKS ***/

    const pageSize = 12;
    const intl = useIntl();
    const [trapImages, setTrapImages] = useState<TrapImage[]>([]);
    const [page, setPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const trapImagesPage = await trapImageApi.list(0, pageSize, 'date', false, undefined, trapPlot.id);
                setPage(trapImagesPage.number);
                setLastPage(trapImagesPage.last);
                setTrapImages(trapImagesPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, trapPlot]);

    /*** METHODS ***/

    const loadMore = async () => {
        try {
            setLoading('loading');
            const trapImagesPage = await trapImageApi.list(page + 1, pageSize, 'date', false, undefined, trapPlot.id);
            const updatedTrapImages = [...trapImages, ...trapImagesPage.content];
            setPage(trapImagesPage.number);
            setLastPage(trapImagesPage.last);
            setTrapImages(updatedTrapImages);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/
    return (
        <Row>
            <Col lg={24}>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={trapImages}
                    loading={loading === 'loading'}
                    loadMore={
                        trapImages && !lastPage ? (
                            <div className={styles.buttons}>
                                <Button type="primary" size="large" onClick={loadMore}>
                                    <FormattedMessage id="button.loadMore" />
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    grid={{
                        gutter: 0,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 3,
                        xxl: 4
                    }}
                    renderItem={(trapImage) => (
                        <List.Item>
                            <Card cover={<Image src={trapImage.url} />} className={styles.card}>
                                <Meta
                                    title={
                                        <FormattedDate
                                            value={trapImage.date as any}
                                            year="numeric"
                                            month="2-digit"
                                            day="2-digit"
                                            hour="2-digit"
                                            minute="2-digit"
                                        />
                                    }
                                    description={
                                        trapImage.insectsCount === undefined
                                            ? '-'
                                            : `${trapImage.insectsCount} ${intl.formatMessage({ id: 'trapImagesViewer.insects' })}`
                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    );
};
export default TrapImagesViewerComponent;

interface Props {
    trapPlot: TrapPlot;
}
