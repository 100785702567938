import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';
import HomePage from '../../pages/HomePage/HomePage';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const DefaultRoute: React.FC = () => {
    const context = useContext(CustomContext);
    const { auth } = context;

    if (auth && auth.initialized && auth.authorities.includes('ROLE_ADMIN')) {
        return <Navigate to={'/admin/traps'} />;
    } else if (auth && auth.initialized && auth.authorities.includes('ROLE_COMPANY_USER')) {
        return <Navigate to={'/dashboard'} />;
    } else {
        return <HomePage />;
    }
};
export default DefaultRoute;
