import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const InsectsVariationComponent: React.FC<InsectsVariationProps> = (props) => {
    const { insectsVariation } = props;

    if (insectsVariation !== undefined && insectsVariation > 0) {
        return (
            <>
                <ArrowUpOutlined />
                {Math.abs(insectsVariation)} <FormattedMessage id="insectsVariation.insects" />
            </>
        );
    } else if (insectsVariation !== undefined && insectsVariation < 0) {
        return (
            <>
                <ArrowDownOutlined />
                {Math.abs(insectsVariation)} <FormattedMessage id="insectsVariation.insects" />
            </>
        );
    } else if (insectsVariation !== undefined && insectsVariation === 0) {
        return (
            <>
                <FormattedMessage id="insectsVariation.none" />
            </>
        );
    } else {
        return (
            <>
                <FormattedMessage id="insectsVariation.unknown" />
            </>
        );
    }
};
export default InsectsVariationComponent;

export interface InsectsVariationProps {
    insectsVariation: number | undefined;
}
