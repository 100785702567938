import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import trapApi from '../../apis/TrapApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Page } from '../../models/Elements';
import { Trap } from '../../models/Entities';
import alertService from '../../services/AlertService';
import paginationService from '../../services/TableService';

/**
 * Returns the admin traps page.
 * @returns the admin traps page.
 */

const AdminTrapsPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [adminTrapsPage, setAdminTrapsPage] = useState<Page<Trap>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('uuid');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    //list traps
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // load traps page
                const adminTrapsPage = await trapApi.list(page, paginationService.pageSize, sortField, sortOrder, undefined, filter.searchText);
                setAdminTrapsPage(adminTrapsPage);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
        setPage(0);
    };

    /*** VISUAL ***/

    const items = adminTrapsPage ? adminTrapsPage.content : [];
    const columns: ColumnsType<Trap> = [
        {
            title: <FormattedMessage id="trap.uuid" />,
            dataIndex: 'uuid',
            key: 'uuid',
            width: 180,
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string | undefined, trap: Trap) => <Link to={`/admin/traps/${trap.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="trapPlot.plague" />,
            dataIndex: 'plague',
            key: 'plague',
            width: 240,
            render: (value: string | undefined, trap: Trap) => (
                <Link to={`/admin/traps/${trap.id}`}>
                    <ConstantLabel prefix="plague.type." value={value} />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trap.company" />,
            dataIndex: 'companyName',
            key: 'companyName',
            sorter: true,
            render: (value: number | undefined, trap: Trap) => <Link to={`/admin/traps/${trap.id}`}>{trap.company?.name}</Link>
        },
        {
            title: <FormattedMessage id="trap.audit.created" />,
            dataIndex: ['audit', 'created'],
            key: 'created',
            width: 200,
            align: 'center',
            render: (value: string | undefined, trap: Trap) => (
                <Link to={`/admin/traps/${trap.id}`}>
                    <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trap.audit.updated" />,
            dataIndex: ['audit', 'updated'],
            key: 'updated',
            width: 200,
            align: 'center',
            render: (value: string | undefined, trap: Trap) => (
                <Link to={`/admin/traps/${trap.id}`}>
                    <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" />
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="traps.title" />}
            menu="traps"
            path={[{ path: '/admin/traps', name: <FormattedMessage id="traps.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={20} lg={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'traps.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} lg={6} className="table-buttons">
                        <Link to="/admin/traps/new">
                            <Tooltip title={<FormattedMessage id="traps.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(adminTrapsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default AdminTrapsPage;

interface Filter {
    searchText?: string | undefined;
}
