import axios, { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import configuration from '../configs/Configuration';
import authService from '../services/AuthService';

/****** RESOURCES APIS ***/

// request interceptor
const axiosRequestInterceptor = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    let accessToken: string | undefined;
    try {
        accessToken = await authService.getAccessToken();
    } catch (error) {
        accessToken = undefined;
    }

    return Promise.resolve({
        ...config,
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
            ...config.headers
        } as AxiosRequestHeaders
    });
};

/*** CORE API CLIENT ***/

const coreApiAxiosInstance: AxiosInstance = axios.create({
    baseURL: configuration.coreApiUrl
});
coreApiAxiosInstance.interceptors.request.use(axiosRequestInterceptor);
export const adminApiAxios = coreApiAxiosInstance;
