import { provinces } from 'all-spanish-cities';
import { Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../AddressComponent/AddressComponent.module.scss';
import alertService from '../../services/AlertService';
import { useEffect, useState } from 'react';
import { Province } from 'all-spanish-cities/dist/types';
import stringService from '../../services/StringService';

const AddressComponent = (props: Props) => {
    const { field, title, disabled } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [sortedProvinces, setSortedProvinces] = useState<any[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const sortedProvinces: Province[] = provinces().sort((a, b) => stringService.sort(a.name, b.name));
                setSortedProvinces(sortedProvinces);
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl]);

    const provincesOptions = sortedProvinces.map((p) => (
        <Select.Option key={p.code} value={p.code}>
            {p.name}
        </Select.Option>
    ));

    return (
        <>
            <Divider orientation="left" className={styles.divider}>
                {title ? title : <FormattedMessage id="address.title" />}
            </Divider>
            <Row gutter={[12, 12]}>
                <Col span={24} lg={22}>
                    <Form.Item label={<FormattedMessage id="address.street" />} name={[field, 'street']}>
                        <Input size="large" maxLength={200} disabled={disabled} />
                    </Form.Item>
                </Col>
                <Col span={12} lg={6}>
                    <Form.Item label={<FormattedMessage id="address.postcode" />} name={[field, 'postcode']}>
                        <InputNumber size="large" min={0} max={99999} controls={false} className={styles.inputNumber} disabled={disabled} />
                    </Form.Item>
                </Col>
                <Col span={12} lg={8}>
                    <Form.Item label={<FormattedMessage id="address.city" />} name={[field, 'city']}>
                        <Input size="large" maxLength={100} disabled={disabled} />
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item label={<FormattedMessage id="address.province" />} name={[field, 'province']}>
                        <Select size="large" allowClear showSearch filterOption={stringService.filterOptions} disabled={disabled}>
                            {provincesOptions}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12} lg={12}>
                    <Form.Item
                        label={<FormattedMessage id="address.email" />}
                        name={[field, 'email']}
                        rules={[
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />
                            }
                        ]}
                    >
                        <Input size="large" maxLength={200} disabled={disabled} />
                    </Form.Item>
                </Col>
                <Col span={12} lg={10}>
                    <Form.Item label={<FormattedMessage id="address.phone" />} name={[field, 'phone']}>
                        <Input size="large" maxLength={50} disabled={disabled} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default AddressComponent;

interface Props {
    field: string;
    title?: React.ReactElement;
    disabled?: boolean;
}
