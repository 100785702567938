import { Page } from '../models/Elements';
import { User } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CompanyUserApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, companyId: number, searchText?: string): Promise<Page<User>> => {
        const response = await adminApiAxios.get<Page<User>>(`/companies/${companyId}/users`, {
            params: { page, size, sortField, sortOrder, companyId, searchText, status: 'ENABLED' }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (companyId: number, id: number): Promise<User> => {
        const response = await adminApiAxios.get<User>(`/companies/${companyId}/users/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (user: User): Promise<User> => {
        const response = await adminApiAxios.post<User>(`/companies/${user.companyId}/users`, user);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (user: User): Promise<User> => {
        const response = await adminApiAxios.put<User>(`/companies/${user.companyId}/users/${user.id}`, user);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (companyId: number, id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/companies/${companyId}/users/${id}`);

        return response.data;
    };

    /**
     * Loads the dates of a council user.
     * @param user - the council user
     */
    private loadDates(user: User) {
        user.audit!.created = dateService.transformDate(user.audit!.created)!;
        user.audit!.updated = dateService.transformDate(user.audit!.updated)!;
    }
}

const companyUserApi: CompanyUserApi = new CompanyUserApi();
export default companyUserApi;
