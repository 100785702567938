import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import { Auth } from 'aws-amplify';
import { CustomAuth } from './models/Entities';
import { ConfigProvider, message } from 'antd';
import authService from './services/AuthService';
import esES from 'antd/lib/locale/es_ES';
import appThemeComponent from './components/AppThemeComponent/AppThemeComponent';
import CustomContext from './contexts/CustomContext';
import './App.css';
import configuration from './configs/Configuration';
import { IntlProvider } from 'react-intl';
import messagesEs from './i18n/es.json';
import HeadMetadata from './components/HeadMetadata';
import RoutesComponent from './components/RoutesComponent/RoutesComponent';
import { App as AntdApp } from 'antd';
import notificationUserApi from './apis/NotificationUserApi';

// set up DayJs
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);

// set up AWS authentication
Auth.configure(configuration.awsAuth);

// return the app

const App: React.FC = () => {
    /*** HOOKS ***/

    // create context
    const [auth, setAuth] = useState<CustomAuth>();
    const [notifications, setNotifications] = useState<number>(0);
    const [initialized, setInitialized] = useState<boolean>(false);

    // initialize the app
    useEffect(() => {
        const init = async () => {
            // set up antd configuration and locale
            message.config({ maxCount: 1 });
            dayjs.locale('es');

            const auth = await authService.get();
            const notifications = await countNotificiations(auth);

            // store deep link
            storeDeepLink(auth);

            setAuth(auth);
            setNotifications(notifications);
            setInitialized(true);
        };
        init();
    }, []);

    /*** METHODS ***/

    const countNotificiations = async (auth?: CustomAuth): Promise<number> => {
        let notifications: number = 0;
        if (auth && auth.initialized) {
            notifications = await notificationUserApi.countRead(false);
        }

        return notifications;
    };

    const updateContextAuth = (auth?: CustomAuth): void => {
        setAuth(auth);
    };

    const updateContextNotifications = (notifications: number): void => {
        setNotifications(notifications);
    };

    /*** VISUAL ***/

    if (initialized) {
        return (
            <ConfigProvider locale={esES} theme={appThemeComponent.getAppTheme()}>
                <AntdApp>
                    <CustomContext.Provider value={{ auth, setAuth: updateContextAuth, notifications, setNotifications: updateContextNotifications }}>
                        <IntlProvider locale="es-ES" defaultLocale="es-ES" messages={messagesEs}>
                            <HeadMetadata />
                            <RoutesComponent />
                        </IntlProvider>
                    </CustomContext.Provider>
                </AntdApp>
            </ConfigProvider>
        );
    } else {
        return <></>;
    }
};

export default App;

/**
 * Stores the deep link if the user is not authenticated or not initialized.
 * @param auth the auth
 * @returns the current deep link
 */
const storeDeepLink = (auth: CustomAuth | undefined) => {
    if (!auth || !auth.initialized) {
        sessionStorage.setItem('deeplink', window.location.pathname + window.location.search);
    }
};
