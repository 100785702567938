import { Col, Form, Row, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import companyApi from '../../apis/CompanyApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import ProvinceComponent from '../../components/ProvinceComponent/ProvinceComponent';
import { Page } from '../../models/Elements';
import { Company } from '../../models/Entities';
import alertService from '../../services/AlertService';
import paginationService from '../../services/TableService';

/**
 * Returns the admin companies page.
 * @returns the admin companies page.
 */
const AdminCompaniesPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [companies, setCompanies] = useState<Page<Company>>();
    const [loading, setLoading] = useState<'loading'>();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<boolean>(true);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const companies = await companyApi.list(page, paginationService.pageSize, sortField, sortOrder, filter.searchText);
                setCompanies(companies);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
        setPage(0);
    };

    /*** VISUAL ***/

    const items = companies ? companies.content : [];
    const columns: ColumnsType<Company> = [
        {
            title: <FormattedMessage id="company.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string | undefined, company: Company) => <Link to={`/admin/companies/${company.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.province" />,
            dataIndex: ['contactAddress', 'province'],
            key: 'province',
            width: 180,
            render: (value: string | undefined, company: Company) => (
                <Link to={`/admin/companies/${company.id}`}>
                    <ProvinceComponent provinceId={value} />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="address.city" />,
            dataIndex: ['contactAddress', 'city'],
            key: 'city',
            width: 200,
            render: (value: string | undefined, company: Company) => <Link to={`/admin/companies/${company.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.email" />,
            dataIndex: ['contactAddress', 'email'],
            key: 'email',
            width: 200,
            render: (value: string | undefined, company: Company) => <Link to={`/admin/companies/${company.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.phone" />,
            dataIndex: ['contactAddress', 'phone'],
            key: 'phone',
            align: 'center',
            width: 180,
            render: (value: string | undefined, company: Company) => <Link to={`/admin/companies/${company.id}`}>{value}</Link>
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="companies.title" />}
            menu="companies"
            path={[{ path: '/admin/companies', name: <FormattedMessage id="companies.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={24} lg={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'companies.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(companies)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default AdminCompaniesPage;

interface Filter {
    searchText?: string | undefined;
}
