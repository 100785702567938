import { Card, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import MapComponent from '../../components/MapComponent/MapComponent';
import styles from './DashboardPage.module.scss';
import NotificationsComponent from './NotificationsComponent/NotificationsComponent';
import ChartInsectsComponent from '../../components/ChartInsectsComponent/ChartInsectsComponent';
import ChartTemperatureComponent from '../../components/ChartTemperatureComponent/ChartTemperatureComponent';
import ChartHumidityComponent from '../../components/ChartHumidityComponent/ChartHumidityComponent';

/**
 * Returns the dashboard page.
 * @returns the dashboard page.
 */
const DashboardPage: React.FC = () => {
    /*** HOOKS ***/

    const [desktop] = useResponsiveLayout();

    // initialize app
    useEffect(() => {
        const init = async () => {};
        init();
    }, []);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="dashboard.title" />} menu="dashboard">
            <div className={styles.container}>
                <Row gutter={[24, 24]}>
                    {/* <Col xs={24} lg={8} className={!desktop ? styles.panelBottom : undefined}>
                        <StatusComponent />
                    </Col> */}
                    <Col xs={24} lg={18}>
                        <MapComponent />
                    </Col>
                    <Col xs={24} lg={6}>
                        <Card bordered={false} className={styles.card} title={<FormattedMessage id="dashboard.notifications.title" />}>
                            <NotificationsComponent />
                        </Card>
                    </Col>
                    <Col xs={24} lg={24}>
                        <ChartInsectsComponent />
                    </Col>
                    <Col xs={24} lg={12}>
                        <ChartTemperatureComponent />
                    </Col>
                    <Col xs={24} lg={12} className={desktop ? undefined : styles.panelMobile}>
                        <ChartHumidityComponent />
                    </Col>
                    {/* <Col xs={24} lg={8} className={!desktop ? styles.panelBottom : undefined}>
                        <InsectsChartComponent />
                    </Col>  */}
                </Row>
            </div>
        </LayoutComponent>
    );
};
export default DashboardPage;
