import dayjs, { Dayjs } from 'dayjs';

class DateService {
    sort = (a: Dayjs | undefined, b: Dayjs | undefined): number => {
        return dayjs(a).isAfter(dayjs(b)) ? 1 : -1;
    };

    /**
     * Changes date format to local
     * @param date the date
     * @returns the transformed date
     */
    transformDate = (date?: Dayjs): Dayjs | undefined => {
        return date && dayjs.utc(date).local();
    };
}

const dateService: DateService = new DateService();
export default dateService;
