import dayjs, { Dayjs } from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import trapImageApi from '../../apis/TrapImageApi';
import CustomContext from '../../contexts/CustomContext';
import { CustomAuth, Plot, TrapPlot, TrapImageStats } from '../../models/Entities';
import alertService from '../../services/AlertService';
import dateService from '../../services/DateService';
import stringService from '../../services/StringService';
import styles from './ChartInsectsComponent.module.css';
import { Card } from 'antd/lib';
import { TimeRangePickerProps, DatePicker } from 'antd';

/**
 * Returns the chart insects component.
 * @returns the chart insects component
 */
const ChartInsectsComponent: React.FC<Props> = (props) => {
    const { plot, trapPlot } = props;
    const { RangePicker } = DatePicker;

    /*** HOOKS ***/

    const intl = useIntl();
    const [dataset, setDataset] = useState<(string | number | undefined)[][]>([]);
    const [series, setSeries] = useState<string[]>([]);
    const [dimensions, setDimensions] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(14, 'days'));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs());
    const [, setLoading] = useState<'loading'>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;

    /*** EFFECTS ***/

    useEffect(() => {
        const buildChartData = (trapImageStats: TrapImageStats[]): any => {
            const trapPlots: TrapPlot[] = trapImageStats
                .map((tis) => tis.trapPlot)
                .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
                .sort((a, b) => stringService.sort(a.trap?.uuid, b.trap?.uuid));
            const series: string[] = trapPlots.map((te) => `${intl.formatMessage({ id: `plague.type.${te.trap?.plague}` })} (${te.trap?.shortUuid})`);
            const dimensions: string[] = ['timestamp', ...series];

            const dates: Dayjs[] = trapImageStats
                .map((tis) => tis.date)
                .map((d) => d.startOf('day'))
                .filter((value, index, self) => index === self.findIndex((t) => t.isSame(value, 'day')))
                .sort(dateService.sort);
            const dataset: (string | number | undefined)[][] = dates.map((date) => {
                const values: (number | undefined)[] = trapPlots.flatMap((trapPlot) =>
                    trapImageStats
                        .filter((tis) => tis.trapPlot.id === trapPlot.id && tis.date.isSame(date, 'day'))
                        .map((tis) => tis.maxInsects)
                        .find((maxInsects) => maxInsects !== undefined)
                );

                return [date.toISOString(), ...values];
            });

            return { series, dimensions, dataset };
        };

        const init = async () => {
            try {
                setLoading('loading');
                let trapImageStats: TrapImageStats[] = [];
                if (auth.companyId && trapPlot && trapPlot.id) {
                    trapImageStats = await trapImageApi.listStats(startDate, endDate, 'DAY', auth.companyId, undefined, trapPlot.id);
                } else if (auth.companyId && plot && plot.id) {
                    trapImageStats = await trapImageApi.listStats(startDate, endDate, 'DAY', auth.companyId, plot.id);
                } else if (auth.companyId && !plot) {
                    trapImageStats = await trapImageApi.listStats(startDate, endDate, 'DAY', auth.companyId);
                }
                const { dimensions, series, dataset } = buildChartData(trapImageStats);

                setSeries(series);
                setDimensions(dimensions);
                setDataset(dataset);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, auth.companyId, plot, trapPlot, startDate, endDate]);

    /*** METHODS ***/

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            const startDate = dateStrings[0];
            const endDate = dateStrings[1];

            setStartDate(dayjs(startDate));
            setEndDate(dayjs(endDate));
        }
    };

    /*** VISUAL ***/

    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: <FormattedMessage id={`dashboard.range.7.days`} />, value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: <FormattedMessage id={`dashboard.range.14.days`} />, value: [dayjs().add(-14, 'd'), dayjs()] },
        { label: <FormattedMessage id={`dashboard.range.30.days`} />, value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: <FormattedMessage id={`dashboard.range.90.days`} />, value: [dayjs().add(-90, 'd'), dayjs()] }
    ];

    return (
        <div className={styles.container}>
            <Card
                bordered={false}
                className={styles.card}
                title={<FormattedMessage id="dashboard.chart.insects.title" />}
                extra={
                    <RangePicker
                        presets={rangePresets}
                        size="small"
                        onChange={onRangeChange}
                        style={{ maxWidth: 220 }}
                        defaultValue={[startDate, endDate]}
                        allowClear={false}
                    />
                }
            >
                <ReactECharts
                    option={{
                        legend: {
                            bottom: 0
                        },
                        grid: {
                            top: 10
                        },
                        dataset: {
                            source: dataset,
                            dimensions
                        },
                        xAxis: { type: 'time' },
                        yAxis: {},
                        series: series.map((s) => ({
                            name: s,
                            symbol: 'emptyCircle',
                            type: 'line',
                            connectNulls: true,
                            smooth: true,
                            encode: {
                                x: 'timestamp',
                                y: s
                            }
                        })),
                        tooltip: {
                            trigger: 'axis',
                            valueFormatter: (value: number | undefined) =>
                                value === undefined ? '' : `${value} ${intl.formatMessage({ id: 'chart.insects' })}`,
                            axisPointer: {
                                label: {
                                    formatter: function (params: any) {
                                        return dayjs(params.value).format('DD/MM/YYYY');
                                    }
                                }
                            }
                        }
                    }}
                />
            </Card>
        </div>
    );
};
export default ChartInsectsComponent;

interface Props {
    plot?: Plot;
    trapPlot?: TrapPlot;
}
