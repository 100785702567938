import { Page } from '../models/Elements';
import { CompanyAdmin, User } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class UserApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, searchText?: string): Promise<Page<User>> => {
        const response = await adminApiAxios.get<Page<User>>('/users', {
            params: { page, size, sortField, sortOrder, searchText, status: 'ENABLED' }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (userId: number): Promise<User> => {
        const response = await adminApiAxios.get<User>(`/users/${userId}`);
        this.loadDates(response.data);

        return response.data;
    };

    getCurrent = async (): Promise<User> => {
        const response = await adminApiAxios.get<User>(`/users/current`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (user: User): Promise<User> => {
        const response = await adminApiAxios.post<User>(`/users`, user);
        this.loadDates(response.data);

        return response.data;
    };

    createCompanyAdmin = async (user: CompanyAdmin): Promise<User> => {
        const response = await adminApiAxios.post<CompanyAdmin>('/users/company_admins', user);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (user: User): Promise<User> => {
        const response = await adminApiAxios.put<User>(`/users/${user.id}`, user);
        this.loadDates(response.data);

        return response.data;
    };

    updateCurrent = async (user: User): Promise<User> => {
        const response = await adminApiAxios.put<User>(`/users/current`, user);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (userId: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/users/${userId}`);

        return response.data;
    };

    /**
     * Loads the dates of a user.
     * @param user - the user
     */
    private loadDates(user: User) {
        user.audit!.created = dateService.transformDate(user.audit!.created)!;
        user.audit!.updated = dateService.transformDate(user.audit!.updated)!;
    }
}

const userApi: UserApi = new UserApi();
export default userApi;
